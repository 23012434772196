import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import NextImage from "next/image";

import carouselOne from "../../../public/carouselOne.png";
import carouselTwo from "../../../public/carouselTwo.png";
import carouselThree from "../../../public/carouselThree.png";
import { inter } from "@/styles/font";
import { IFeaturedMarket } from "@/lib/interfaces/IFeaturedMarket";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import styles from '../../styles/Home.module.css'

export const MarketItem = ({ market: item }: { market: IFeaturedMarket }) => {
  return (
    <Flex
      flexDirection={"column"}
      width={"100%"}
      css={{
        backgroundImage: `url(${item.market.image[0]})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      position={"relative"}
      pl={4}
      py={8}
      color={"white"}
      rounded={"md"}
      overflow={"hidden"}
    >
      <Box
        bgColor={"blackAlpha.700"}
        w={"full"}
        h={"full"}
        position={"absolute"}
        top={0}
        left={0}
      />
      <HStack zIndex={"dropdown"}>
        {/* <Circle size={'5px'} bgColor={'success.600'} /> */}
        <Text
          fontSize={"sm"}
          fontWeight={"semibold"}
          className={inter.className}
        >
          {item.market.title}
        </Text>
      </HStack>
      <Text
        zIndex={"dropdown"}
        fontSize={"sm"}
        fontWeight={"normal"}
        className={inter.className}
      >
        {item.market.state.title}
      </Text>
      {/* <HStack zIndex={"dropdown"}>
        <NextImage src={shop} alt={""} />
        <Text fontSize={"sm"} fontWeight={"normal"} className={inter.className}>
          900 shops
        </Text>
      </HStack>
      <HStack zIndex={"dropdown"}>
        <NextImage src={box} alt={""} />
        <Text fontSize={"sm"} fontWeight={"normal"} className={inter.className}>
          1000 products
        </Text>
      </HStack> */}
    </Flex>
  );
};

const Banner = () => {

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"full"}
      width={"100%"}
      // px={{base: 4, lg: 0}}
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={40}
        centeredSlidesBounds={true}
        modules={[Autoplay]}
        autoplay={{
          disableOnInteraction: false,
          delay: 4000
        }}
        style={{
          width: "100%",
        }}
      >
        <SwiperSlide>
          <Box className={styles.imageContainer}>
            <NextImage src={carouselOne} alt={''} loading={'eager'} fill priority className={styles.image} />
          </Box>
        </SwiperSlide>

        <SwiperSlide style={{ position: 'relative' }}>
          <Box className={styles.imageContainer}>
            <NextImage src={carouselTwo} alt={''} loading={'eager'} fill priority className={styles.image} />
          </Box>
        </SwiperSlide>

        <SwiperSlide style={{ position: 'relative' }}>
          <Box className={styles.imageContainer}>
            <NextImage src={carouselThree} alt={''} loading={'eager'} fill priority className={styles.image} />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Flex>
  );
};

export default Banner;
