import { UserContext } from "@/userContext";
import { Flex, Grid, GridItem, Show } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useContext } from "react";
import Banner from "../banner/Banner";

const Profile = dynamic(() => import("../profile/Profile"));
const Welcome = dynamic(() => import("../welcome/Welcome"));
const Category = dynamic(() => import("../category/Category"));
const CategorySM = dynamic(() => import("../category/CategorySM"));
const Market = dynamic(() => import("../market/Market"));
const FeaturedMarketSM = dynamic(() => import("../category-item/FeaturedMarketSM"));

const Header = () => {
  const profile = useContext(UserContext);

  return (
    <Grid
      mt={6}
      templateColumns={{ base: "1fr", lg: "repeat(4, 1fr)" }}
      gridAutoRows={{ lg: "1fr" }}
      columnGap={4}
      rowGap={{ base: 4, lg: 0 }}
    >
      <GridItem gridColumn={"1 / 2"} gridRow={"1 / 4"} display={{ base: 'none', lg: 'initial' }}>
        <Category category={[]} />
      </GridItem>
      <GridItem px={{ base: 5, lg: 0 }} gridColumn={{ base: "1 / span 5", lg: "2 / 4" }}
        gridRow={{ base: "1 / 2", lg: "1 / 3" }}>
        <Banner />
      </GridItem>
      {/* category-sm */}
      <GridItem px={{ base: 5, lg: 0 }} gridColumn={"1 / span 5"}
        gridRow={"2 / 4"} display={{ base: 'initial', lg: 'none' }}>
        <CategorySM />
      </GridItem>
      <GridItem px={{ base: 5, lg: 0 }} alignSelf={'end'}
        gridColumn={{ base: "1 / span 5", lg: "2 / 5" }} gridRow={{ base: "4 / 5", lg: "3 / 4" }}>
        <Show above={'md'}>
          <Market />
        </Show>
        <Show below={'md'}>
          { profile?.user && <FeaturedMarketSM /> }
        </Show>
      </GridItem>
      <GridItem gridColumn={"4 / 5"} gridRow={"1 / 3"} display={{ base: 'none', lg: 'initial' }}>
        <Flex
          h={"full"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          {
            profile?.user ?
              <Profile phone={profile.user.phone} image={profile.user.image} /> :
              <Welcome />
          }
        </Flex>
      </GridItem>
    </Grid>
    // <Grid
    //   mt={6}
    //   templateColumns={"repeat(4, 1fr)"}
    //   gridAutoRows={"1fr"}
    //   columnGap={4}
    // >
    //   <GridItem gridColumn={"1 / 2"} gridRow={"1 / 4"}>
    //     <Category category={[]} />
    //   </GridItem>
    //   <GridItem gridColumn={"2 / 4"} gridRow={"1 / 3"}>
    //     <Banner />
    //   </GridItem>
    //   <GridItem alignSelf={'end'} gridColumn={"2 / 5"} gridRow={"2 / 4"}>
    //     <Market />
    //   </GridItem>
    //   <GridItem gridColumn={"4 / 5"} gridRow={"1 / 3"}>
    //     <Flex
    //       h={"full"}
    //       flexDirection={"column"}
    //       justifyContent={"space-between"}
    //     >
    //       {
    //         profile?.user ?
    //           <Profile phone={profile.user.phone} image={profile.user.image} /> :
    //           <Welcome />
    //       }
    //     </Flex>
    //   </GridItem>
    // </Grid>
  );
};

export default Header;